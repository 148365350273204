import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { node } from "prop-types"

function BlogHome() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { type: { eq: "blog" }, draft: { ne: true } } }
        limit: 1000
      ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            html
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              type
              tags
            }
          }
        }
      }
    }
  `)
  return (
    <Layout cssClass="card">
      <SEO title="Blog"></SEO>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <article class="blogPost">
          {
            <h2>
              <Link to={node.fields.slug} className="marker">
                {node.frontmatter.title}
              </Link>
            </h2>
          }
          {node.frontmatter.tags &&
            node.frontmatter.tags.map(tag => (
              <span className="tag"> {tag} </span>
            ))}
          <div>{new Date(node.frontmatter.date).toDateString()}</div>
          <p>{node.excerpt}</p>
        </article>
      ))}
    </Layout>
  )
}

export default BlogHome
